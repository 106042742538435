<template>
  <div>
    <p class="compont_p">会员资质证件上传</p>
    <div class="flex_row" style="flex-wrap: wrap">
      <div class="item" v-for="item in list" :key="item">
        <upload :url="item.url" :uploadkey="item" @successUrl="successUrl" @deleteImage="deleteImage" />
        <!-- <p>{{ item.title }}</p> -->
        <!-- <p>到期时间：{{ item.date }}</p> -->
        <div>{{ item.title }}</div>
        <div>到期时间：{{ item.date }}</div>
        <!-- <div v-if="item.downUrl" class="down-btn" @click="downloadFile(item)">下载</div> -->
        <div v-if="item.downUrl" class="down-btn" @click="downFile(item)">下载</div>
      </div>
    </div>
  </div>
</template>

<script>
import upload from "@/components/upload.vue";
export default {
  components: {
    upload,
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$api("account.getCertificate").then((res) => {
        this.list = res.data;
      });
    },
    successUrl(data, item) {
      console.log(data, item);
      item.url = data.url;
      this.$api("account.updateCertificate", item).then(() => {
        this.$message.success("上传成功");
        this.getData();
      });
    },
    deleteImage(key) {
      this.$api("account.updateCertificate", { [key]: "del" }).then(() => {});
    },
    urlDownload(downUrl, fileName) {
      var a = document.createElement("a");
      a.href = downUrl;
      a.download = fileName;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },

    downloadByBlob(url, name) {
      let image = new Image();
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
      image.onload = () => {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, image.width, image.height);
        canvas.toBlob((blob) => {
          let url = URL.createObjectURL(blob);
          this.urlDownload(url, name);
          // 用完释放URL对象
          URL.revokeObjectURL(url);
        });
      };
    },

    downloadIamge(imgsrc, name) {
      //下载图片地址和图片名
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据

        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },

    downFile(data) {
      console.log("下载信息------", data);
      // window.location.href = data.downUrl;
      this.urlDownload(data.downUrl, data.title);
      // this.test(data.downUrl, data.title);
      // this.downloadByBlob(data.downUrl, data.title);
      // this.downloadIamge(data.downUrl, data.title);
    },
  },
};
</script>

<style lang="less" scoped>
.compont_p {
  color: @themeColor!important;
}

.item {
  margin: 20px;
  height: 218px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  line-height: 1.5;
  p {
    font-weight: 400;
    color: #333333;
  }
  & > p:last-child {
    color: #666;
  }
  .down-btn {
    cursor: pointer;
    color: rgb(191, 25, 25);
  }
}
</style>
